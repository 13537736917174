<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date To</label>
            <b-input
              id="date_from"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Process Status</label>
            <b-select
              v-model="filter.process_status"
              :options="filterProcessStatuses"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowViewForm(row.item)"
            >
              View
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal -->
    <b-modal
      id="modal-view-form"
      scrollable
      no-close-on-backdrop
      title="View Detail"
    >
      <b-card no-body>
        <form
          novalidate
          role="form"
        >
          <b-tabs>
            <b-tab
              title="Request Details"
              active
            >
              <b-form-group>
                <label for="request_date">Request Date</label>
                <b-input
                  id="request_date"
                  v-model="paymentRequest.created_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="request_status">Request Status</label>
                <b-input
                  id="request_status"
                  v-model="paymentRequest.request_status"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="request_payee">Request Payee</label>
                <b-input
                  id="request_payee"
                  v-model="paymentRequest.request_payee"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="amount">Request Amount</label>
                <b-input
                  id="request_amount"
                  v-model="paymentRequest.request_amount"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="budget_name">Budget Name</label>
                <b-input
                  id="budget_name"
                  v-model="paymentRequest.budget_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="budget_actual_name">Budget Actual</label>
                <b-input
                  id="budget_actual_name"
                  v-model="paymentRequest.budget_actual_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="item_name">Item Description</label>
                <b-input
                  id="item_name"
                  v-model="paymentRequest.item_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="request_description">Request Description</label>
                <b-form-textarea
                  id="request_description"
                  v-model="paymentRequest.request_description"
                  rows="6"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="payment_mode">Payment Mode</label>
                <b-input
                  id="payment_mode"
                  v-model="paymentRequest.payment_mode"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_mode === 'Check' || paymentRequest.payment_mode === 'Cash'">
                <label for="payment_action">Payment Action</label>
                <b-input
                  id="payment_action"
                  v-model="paymentRequest.payment_action"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                <label for="bank_account_number">Bank Account Number</label>
                <b-input
                  id="bank_account_number"
                  v-model="paymentRequest.bank_account_number"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                <label for="bank_account_name">Bank Account Name</label>
                <b-input
                  id="bank_account_name"
                  v-model="paymentRequest.bank_account_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                <label for="bank">Bank</label>
                <b-input
                  id="bank"
                  v-model="paymentRequest.bank"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="user_name">Requested By</label>
                <b-input
                  id="user_name"
                  :value="paymentRequest.user_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="approval_one_name">Noted By</label>
                <b-input
                  id="approval_one_name"
                  v-model="paymentRequest.approval_one_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="approval_one_at">Noted At</label>
                <b-input
                  id="approval_one_at"
                  v-model="paymentRequest.approval_one_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="approval_one_remarks">Noted By Remarks</label>
                <b-textarea
                  id="approval_one_remarks"
                  v-model="paymentRequest.approval_one_remarks"
                  rows="6"
                  :disabled="true"
                />
              </b-form-group>
            </b-tab>

            <b-tab
              v-if="paymentRequest.file_list.length>0"
              title="Attachments"
            >
              <b-button
                size="sm"
                variant="success"
                class="mb-2"
                @click="onPreviewAllAttachment"
              >
                Preview All
              </b-button>

              <b-table
                class="mt-1"
                :fields="attachmentTableSettings.fields"
                :items="paymentRequest.file_list"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-tab>

            <b-tab
              v-if="paymentRequest.approval_three_status !== 'Pending'"
              :title="paymentRequest.approval_three_status === 'Approved' ? `Budget Approved` : `Budget Disapproved`"
            >
              <b-form-group>
                <label for="approval_three_remarks">Remarks</label>
                <b-textarea
                  id="approval_three_remarks"
                  :value="paymentRequest.approval_three_remarks"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>
            </b-tab>

          </b-tabs>
        </form>
      </b-card>

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-pdf"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${previewFilename}]`"
      size="lg"
    >
      <vue-pdf-embed :source="preview" />

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!--  modal -->
    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${previewFilename}]`"
      size="lg"
    >
      <div id="printMe2">
        <b-img
          fluid
          :src="preview"
        />
      </div>
      <template #modal-footer="{cancel}">
        <b-button
          v-print="'#printMe2'"
          variant="success"
        >
          Print Attachment
        </b-button>

        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-all-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachments`"
      size="lg"
    >
      <div id="printMe3">
        <table>
          <tr
            v-for="attachment in allAttachments"
            :key="attachment"
          >
            <td
              class="p-0 m-0"
              style="page-break-after: always"
            >
              <b-img
                fluid
                :src="attachment"
                style="height: 100vh;"
              />
            </td>
          </tr>
        </table>
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          v-print="'#printMe3'"
          variant="success"
        >
          Print All
        </b-button>

        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { RADReportService } from '@/services'
import print from 'vue-print-nb'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'

export default {
  name: 'RADTransactionHistories',

  middleware: ['auth', 'rad'],

  metaInfo () {
    return {
      title: 'Reports - Approval Histories'
    }
  },

  components: {
    VuePdfEmbed
  },

  directives: {
    print
  },

  mixins: [formatter, url],

  data () {
    return {
      preview: null,
      previewFilename: '',
      allAttachments: [],
      filter: {
        transaction_type: 'All',
        process_status: 'All',
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      filterProcessStatuses: [
        { text: 'All', value: 'All' },
        { text: 'Approved', value: 'Approved' },
        { text: 'Disapproved', value: 'Disapproved' }
      ],
      paymentRequest: {
        id: 0,
        request_date: '',
        request_payee: '',
        request_amount: 0,
        transaction_type: '',
        request_status: '',
        budget_name: '',
        budget_actual_name: '',
        item_name: '',
        request_description: '',
        payment_mode: null,
        payment_action: '',
        bank: '',
        bank_account_number: '',
        bank_account_name: '',
        approval_one_name: '',
        approval_one_at: '',
        approval_one_remarks: '',
        approval_three_status: '',
        approval_three_at: '',
        approval_three_remarks: '',
        document_number: '',
        file_path: '',
        file_list: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'request date', sortable: true },
          { key: 'request_payee' },
          { key: 'request_amount', formatter: this.numberFormatter, class: 'text-right' },
          { key: 'request_status' },
          { key: 'document_number' },
          { key: 'user_name', label: 'requested by' },
          { key: 'approval_three_status', label: 'process status' },
          { key: 'approval_three_name', label: 'process by' },
          { key: 'approval_three_at', label: 'process at', sortable: true }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await RADReportService.getApprovalHistories(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_type=${this.filter.transaction_type}&filter_status=${this.filter.process_status}&filter_from=${this.filter.date_from}&filter_to=${this.filter.date_to}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onShowViewForm (item) {
      this.paymentRequest.id = item.id
      this.paymentRequest.created_at = this.dateTimeFormatter(item.created_at)
      this.paymentRequest.request_payee = item.request_payee
      this.paymentRequest.request_amount = this.numberFormatter(item.request_amount)
      this.paymentRequest.transaction_type = item.transaction_type
      this.paymentRequest.request_status = item.request_status
      this.paymentRequest.budget_name = item.budget_name
      this.paymentRequest.budget_actual_name = item.budget_actual_name
      this.paymentRequest.item_name = item.item_name
      this.paymentRequest.request_description = item.request_description
      this.paymentRequest.payment_mode = item.payment_mode
      this.paymentRequest.payment_action = item.payment_action
      this.paymentRequest.bank = item.bank
      this.paymentRequest.bank_account_number = item.bank_account_number
      this.paymentRequest.bank_account_name = item.bank_account_name
      this.paymentRequest.user_name = item.user_name
      this.paymentRequest.approval_one_name = item.approval_one_name
      this.paymentRequest.approval_one_at = item.approval_one_at
      this.paymentRequest.approval_one_remarks = item.approval_one_remarks
      this.paymentRequest.approval_three_status = item.approval_three_status
      this.paymentRequest.approval_three_at = item.approval_three_at
      this.paymentRequest.approval_three_remarks = item.approval_three_remarks
      this.paymentRequest.document_number = item.document_number
      this.paymentRequest.file_path = item.file_path
      this.paymentRequest.file_list = item.attachments
      this.$bvModal.show('modal-view-form')
    },

    async onPreviewAttachment (item) {
      this.previewFilename = item.file_original_name
      this.preview = this.getImageUrl(`${this.paymentRequest.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    },

    onPreviewAllAttachment () {
      this.allAttachments = []

      this.paymentRequest.file_list.forEach(file => {
        this.allAttachments.push(this.getImageUrl(`${this.paymentRequest.file_path}/${file.file_hash_name}`))
      })

      this.$bvModal.show('modal-preview-all-image')
    },

    onFilterChange () {
      this.$refs.table.refresh()
    }
  }
}
</script>
